/**
 *
 * @param {*} bid businessId    //错误上报的id
 * @param {*} pid projectId     //单个测算的id
 * 初始化上报sdk的时候使用
 */

const REPORT_OPT = {
    id: 64, //错误上报的id
    url: 'https://error.youjyi.cn/api/errrorlog/receive', //上报url
    random: 1, //抽样上报
    offlineLog: false, //是否离线上报
    submit: function (url) {
        var query1 = BJ_REPORT.urlSearchParse(url.split('?')[1]);
        var query2 = BJ_REPORT.urlQueryParse(query1);
        BJ_REPORT.request.ajax({
            url: 'https://error.youjyi.cn/api/errrorlog/receive',
            type: 'POST',
            data: query2,
            success: function (e) {
                //do something
            },
            error: function (e) {
                //
            }
        });
    },
    onReport: function (id, errObj) {
        //上报回调
        // console.log(id, errObj);
    }
};

const badjsInit = function () {
    let timer = null;
    let initQueue = () => {
        clearTimeout(timer);
        if (window.BJ_REPORT) {
            let opt = Object.assign({}, REPORT_OPT);
            try {
                BJ_REPORT.init(opt);
            } catch (error) {}
        } else {
            timer = setTimeout(initQueue, 1000);
        }
    };
    initQueue();
};

/**
 *
 * @param {*} options
 * 单独上报的时候使用
 *
 */
export const badjsReport = (
    options = {
        msg: {}, //错误信息
        target: 'unknow', //错误目标接口或文件或组件
        from: location.href, //来源链接
        rowNum: '0', //error出现的行号
        colNum: '0' //error出现的列号
    }
) => {
    if ('object' === typeof options.msg) {
        options.msg = JSON.stringify(options.msg);
    }
    // console.log('错误上报内容：', options);
    window.BJ_REPORT && BJ_REPORT.report(options);
};

export default badjsInit;
