import axios from 'axios';
import qs from 'qs';
import {badjsReport} from './unityErrorEntrance'; //错误上报
import {Toast} from 'vant';
import Cookies from 'js-cookie';
import {getQueryFromUrl} from './tools';
import {CODE, CACHES} from '@/config';

const service = axios.create({
    baseURL: import.meta.env.VITE_APP_BASE_API, // url = base url + request url
    timeout: 6000 // 请求超时
});

// 请求拦截
service.interceptors.request.use(
    (config) => {
        let {data, method} = config;
        const token = Cookies.get(CACHES.TOKEN) || getQueryFromUrl('token') || '';
        let isFormData = (o: any) => !!Object.prototype.toString.call(o).match(/formdata/i);
        if (method === 'post' || method === 'put') {
            if (typeof data === 'string' || isFormData(data)) {
                config.data = data;
            } else {
                config.data = qs.stringify(data);
            }
        }
        // 请求头增加token
        if (token) {
            config.headers.common.token = token;
        }
        return config;
    },
    (error) => {
        // 这里可以留错误上报
        console.log(error); // for debug
        //错误上报
        let {config, data, status} = error.response;
        let requestData = config.params || config.data;
        let reportData = {
            api: config.url,
            status,
            responseData: data,
            requestData
        };
        badjsReport({
            msg: reportData,
            from: window.location.href,
            target: config.url
        });
        return Promise.reject(error);
    }
);

// 响应拦截
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        return res;
    },
    (error: any) => {
        // 错误提示
        if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            Toast('请求超时，当前网络不佳，请稍后再试');
            return Promise.reject(error.response);
        }
        let {config, data, status} = error.response;

        // 错误提示 & 处理
        if (data) {
            if (data.tips) {
                Toast({
                    message: data.tips
                });
            }
            // 登录过期
            if (data.code === CODE.LOGIN_EXPIRED) {
                Cookies.remove(CACHES.TOKEN); // 清除token
                Cookies.remove(CACHES.UID); // 清除uid
                sessionStorage.removeItem(CACHES.FOLLOW_ID);
                if (location.pathname !== '/login') {
                    const fromPath = location.pathname + location.search;
                    const encodePath = encodeURIComponent(fromPath);
                    const path = `/login?from_page=${encodePath}`;
                    setTimeout(() => {
                        location.href = path;
                    }, 1500);
                }
            }
            console.log('错误拦截：', config, data);
        } else {
            Toast('网络连接失败，请稍后再试');
        }

        // 错误上报
        let requestData = config.params || config.data;
        let reportData = {
            api: config.url,
            status,
            responseData: data,
            requestData
        };
        badjsReport({
            msg: JSON.stringify(reportData),
            from: window.location.href,
            target: config.url
        });
        return Promise.reject(error.response);
    }
);

export default service;
