// 大数据sdk
(function () {
    let mta = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
    mta.src = '//aicdn.linghit.com/sense-sdk/latest/sense.sdk.min.js';
    s.parentNode && s.parentNode.insertBefore(mta, s);
})();

// 错误上报sdk
(function () {
    let mta = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
    mta.src = 'https://zxcs.tengzhihh.com/forecastassetbundle/js/bj-report-tryjs.min.js';
    s.parentNode && s.parentNode.insertBefore(mta, s);
})();
