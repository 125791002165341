<template>
    <div class="app-container">
        <router-view></router-view>

        <tab-bar v-if="$route.meta.tabBarShow"></tab-bar>
    </div>
</template>

<script lang="ts">
import {defineComponent, provide, reactive, watch, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import Cookies from 'js-cookie';
import {Toast} from 'vant';
import TabBar from '@/components/tab-bar/index.vue';
import {fetchUserInfo} from '@/api';
import useWechatAuth from '@/hooks/useWechatAuth';
import {CACHES, CODE} from '@/config';
import {isAndriod} from '@/utils/tools';

interface IObj {
    [key: string]: any;
}

export default defineComponent({
    name: 'App',
    components: {
        TabBar
    },
    setup(props) {
        const comData = reactive({
            channel: 'other',
            schannel: '',
            token: '',
            isVip: false, // 是否为vip用户
            hasAddFile: false, // 是否填过档案
            remainAddNum: 0, // 剩余可添加关注的人
            addTimesPrice: 0, // 解锁关注的人单价
            isInput: false, // 当前是否点击input吊起键盘
            userFile: {
                username: '',
                gender: '',
                birthday: ''
            }, // 用户档案（姓名，性别，生日）
            userInfo: {
                phone: '', // 中间带*号的手机号码
                ori_phone: '' // 不带*号的手机号码
            } // 用户信息（手机号码）
        });

        const route = useRoute();
        const router = useRouter();
        const isFirsetEnter = ref(true); // 是否首次进入页面

        useWechatAuth(); // 使用微信授权

        // 没有token的情况必须要登录的页面
        const runLoginWithToken = () => {
            const routeName = route.name as string;
            // 其他需要登录的页面接口有做限制
            const isIncludePage = /result\/bazi|result\/paipan|phonechange/.test(routeName);
            const fromPage = route.fullPath;
            if (!comData.token && isIncludePage) {
                const path = `/login?from_page=${encodeURIComponent(fromPage)}`;
                Toast('未登录，将为您跳转登录页');
                setTimeout(() => {
                    router.push(path);
                }, 2000);
            }
        };

        // 设置参数
        const setParams = () => {
            const {query} = route;
            comData.channel = (query.channel as string) || Cookies.get('channel') || 'other';
            comData.schannel = (query.schannel as string) || Cookies.get('schannel') || '';
            // 缓存channel
            Cookies.set('channel', comData.channel, {expires: 7});

            // 缓存schannel
            if (comData.schannel) {
                Cookies.set('schannel', comData.schannel, {expires: 7});
            }
        };

        // 缓存token和过期时间
        const setTokenAndTime = () => {
            const {token, token_expire_at} = route.query;
            if (token) {
                let expires = token_expire_at
                    ? new Date(String(token_expire_at).replace(/\-/g, '/'))
                    : 7;
                Cookies.set(CACHES.TOKEN, token, {expires});
            }
            comData.token = (token as string) || Cookies.get(CACHES.TOKEN) || '';
            runLoginWithToken();
        };

        // 查询用户数据
        const queryUser = (token?: string) => {
            if (token) {
                comData.token = token;
            }
            if (!comData.token) {
                return;
            }
            fetchUserInfo(comData.token).then(({data, code}: IObj) => {
                if (code === CODE.SUCCESS) {
                    let {
                        is_vip,
                        has_add_file,
                        user_file,
                        user_info,
                        remain_add_num,
                        channel,
                        add_times_price
                    } = data;
                    comData.isVip = is_vip;
                    comData.hasAddFile = has_add_file;
                    comData.remainAddNum = remain_add_num;
                    comData.addTimesPrice = add_times_price;
                    if (user_file) {
                        comData.userFile = user_file;
                    }
                    if (user_info) {
                        comData.userInfo = user_info;
                    }
                    if (!route.query.channel && channel) {
                        comData.channel = channel;
                        Cookies.set('channel', comData.channel, {expires: 7}); // 缓存channel
                    }
                }
            });
        };

        const setIsInput = (val: boolean) => {
            const isAndriodDevice = isAndriod();
            if (isAndriodDevice) {
                comData.isInput = val;
            }
        };

        // 路由变化初始化
        const routeChange = () => {
            setParams(); // 设置参数
            setTokenAndTime(); // 设置token和过期时间
        };

        // 监听路由变化
        watch(
            () => route.name,
            (name, prevName) => {
                if (name && isFirsetEnter.value) {
                    isFirsetEnter.value = false;
                    routeChange();
                }
            }
        );

        //token变化时更新用户数据
        watch(
            () => comData.token,
            (token, prevToken) => {
                console.log('token变更');
                queryUser(); // 查询登录用户信息
            }
        );

        // 提供公共使用的参数和函数，inject接收
        provide('comData', comData); // 通用参数
        provide('updateUser', queryUser); // 更新用户信息
        provide('setIsInput', setIsInput); // 设置输入框是否聚焦
    }
});
</script>

<style lang="less">
@import './assets/less/common.less';
@import './assets/less/reset.less';

.app-container {
    background: #ffffff;
    width: 100%;
    max-width: 750px;
    min-height: 100%;
    position: relative;
    margin: 0 auto;
    color: #2f2323; //字体主色调
    font-size: 28px;
    z-index: 1;
    overflow: hidden;
}
</style>
