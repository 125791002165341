import {createApp} from 'vue';
import 'lib-flexible';
import 'vant/es/toast/style'; // 引入toast样式
import 'vant/es/dialog/style'; // 引入dialog样式
import {createPinia} from 'pinia';
import App from './App.vue';
import router from './router';
import Vconsole from 'vconsole';
import './utils/addCdnSdk'; // 引入sdk
import badjsInit from './utils/unityErrorEntrance'; //错误上报
import bigDataInit from './utils/bigDataTrack'; //埋点
import {Lazyload, Icon} from 'vant';
import {getQueryFromUrl} from '@/utils/tools';

const app = createApp(App);

// vant
app.use(Lazyload);
app.use(Icon);

// 使用pinia
const pinia = createPinia();
app.use(pinia);

router.afterEach((to, from, next) => {
    // 跳转后返回顶部(解决router跳转不置顶)
    window.scrollTo(0, 0);
    // 解决route库版本使用history api的路由无法监听到popstate事件（大数据SDK）
    const event = new Event('hashchange');
    window.dispatchEvent(event);
});

if (getQueryFromUrl('debug') == 'yes' || sessionStorage.getItem('debug') == 'yes') {
    let vsconsole = new Vconsole();
    sessionStorage.setItem('debug', 'yes');
}

// 埋点初始化
bigDataInit();

// 错误上报
badjsInit();

app.use(router).mount('#app');
