import request from '@/utils/request';

/**
 * 下单
 * @param req 下单所需数据
 */
export function order(req: {}) {
    return request({
        url: '/api/orders',
        method: 'post',
        data: req
    });
}

/**
 * 在线下单
 * @param req 下单所需数据
 */
export function onlineOrder(req: {}) {
    return request({
        url: '/api/orders/online_order',
        method: 'post',
        data: req
    });
}

/**
 * 挽留数据
 * @param req 请求所需参数
 */
export function fetchDetain(req: {}) {
    return request({
        url: '/api/common/detain',
        method: 'post',
        data: req
    });
}

/**
 * 获取支付信息
 * @param orderId 订单号
 */
export function fetchPayment(orderId: string) {
    let req = {
        order_id: orderId
    };
    return request({
        url: '/api/payments/config',
        method: 'post',
        data: req
    });
}

/**
 * 获取工具信息
 * @param toolId 工具ID
 */
export function fetchTool(toolId: string) {
    return request({
        url: '/api/tool/get_tool',
        method: 'get',
        params: {
            tool_id: toolId
        }
    });
}

/**
 * 查单
 * @param orderId 订单号
 */
export function fetchOrderStatus(orderId: string) {
    return request({
        url: `/api/orders/${orderId}`,
        method: 'get',
        params: {
            time: Date.now()
        }
    });
}
interface INoResult {
    product_id: string; // 产品id
    channel: string; // 渠道
    extend_info?: string; // JSON.stringify格式化数据
}

/**
 * 无订单结果
 * @param {INoResult} req 请求参数
 */
export function fetchNoOrderResult(req: INoResult) {
    return request({
        url: '/api/results/no_order_result',
        method: 'post',
        data: req
    });
}

/**
 * 获取短信验证码
 * @param req 获取验证码所需数据
 */
export function fetchVerify(req: {}) {
    return request({
        url: '/api/notify/sms_check',
        method: 'get',
        params: req
    });
}

/**
 * 登录
 * @param req 登录所需数据
 */
export function userLogin(req: {}) {
    return request({
        url: '/api/users/login',
        method: 'post',
        data: req
    });
}

/**
 * 获取用户填写表格
 * @param req 请求参数
 */
export function fetchGuideUserInfo(req: {}) {
    return request({
        url: '/api/guide/userinfo',
        method: 'get',
        params: req
    });
}

/**
 * 保存档案
 * @param req 保存档案所需数据
 */
export function saveFile(req: {}) {
    return request({
        url: '/api/users/files',
        method: 'post',
        data: req
    });
}

/**
 * 获取用户档案资料详情
 */
export function fetchUserFileDetail() {
    return request({
        url: '/api/users/file',
        method: 'get'
    });
}

/**
 * 获取用户中心数据
 */
export function fetchUserCenter() {
    return request({
        url: '/api/users/user_center',
        method: 'get'
    });
}

/**
 * 根据token获取用户基本信息
 * @param {string} token
 */
export function fetchUserInfo(token: string) {
    return request({
        url: '/api/users/user_info',
        method: 'get',
        params: {
            token
        }
    });
}

interface IFortuneDetail {
    attention_people_id?: number; // 关注的人ID
    process_time?: string; //时间 默认为当前时间（格式如：2021-7-25 15:27:29）
}
/**
 * 运势详情
 * @param {IFortuneDetail} req 请求参数
 * @returns
 */
export function fetchFortuneDetail(req: IFortuneDetail) {
    return request({
        url: '/api/tool/mei_ri_yun_shi',
        method: 'get',
        params: req
    });
}

/**
 * 获取微信授权
 * @param {string} url
 * @param {string} [scope='snsapi_base']
 */
export function fetchWxCode(url: string, scope: string = 'snsapi_base') {
    return request({
        url: '/api/wechat/geturl',
        method: 'get',
        params: {
            url,
            scope
        }
    });
}

/**
 *
 * 获取微信用户信息
 * @param {string} code
 */
export function fetchWxUser(code: string) {
    return request({
        url: '/api/wechat/user_info',
        method: 'get',
        params: {
            code
        }
    });
}

/**
 * 新增关注的人
 * @param req 请求参数
 * @returns
 */
export function addFollow(req: {}) {
    return request({
        url: '/api/users/attention_peoples',
        method: 'post',
        data: req
    });
}

/**
 * 获取关注人列表
 */
export function fetchFollowList() {
    return request({
        url: '/api/users/attention_peoples',
        method: 'get'
    });
}

/**
 * 获取关注的人运势
 */
export function fetchFollowFortune() {
    return request({
        url: '/api/tool/attention_yun_shi',
        method: 'get'
    });
}

/**
 * 解梦关键词搜索
 * @param {string} keyword
 */
export function fetchJieMengSearch(keyword: string) {
    return request({
        url: '/api/tool/jiemeng_search',
        method: 'get',
        params: {
            keyword
        }
    });
}

/**
 * 梦境关键词搜索
 * @param {string} keyword
 */
export function fetchMengJingSearch(keyword: string) {
    return request({
        url: '/api/tool/mengjing_search',
        method: 'get',
        params: {
            keyword
        }
    });
}

/**
 * 黄历算法
 * @param {string} date 日期 格式如：20000101
 * @returns
 */
export function fetchAlmanac(date: string) {
    return request({
        url: '/api/tool/almanac_day',
        method: 'get',
        params: {
            date
        }
    });
}
interface IQuery {
    type: string; // 查单类型 order_id 或 phone
    order_id?: string; // 订单号
    year?: number; // 年份
    page?: number; // 分页，从1开始
}
/**
 * 多种方式查单
 * @param {IQuery} req 请求参数
 */
export function queryOrders(req: IQuery) {
    return request({
        url: '/api/orders/query',
        method: 'get',
        params: req
    });
}
interface IChange {
    mobile: string; // 原手机号码
    captcha: string; // 原手机号码接收的验证码
    new_mobile: string; // 新手机号码
    new_captcha: string; // 新手机号码接收的验证码
}
export function changePhone(req: IChange) {
    return request({
        url: '/api/users/change_mobile',
        method: 'post',
        data: req
    });
}

/**
 * 获取公号信息
 */
export function fetchOfficalWechat() {
    return request({
        url: '/api/tool/official_account_message',
        method: 'get'
    });
}

/**
 * 判断openid是否绑定过手机号码
 * @param {string} openid 微信openid
 */
export function judgeOpenid(openid: string) {
    return request({
        url: '/api/users/judge_openid',
        method: 'get',
        params: {
            openid
        }
    });
}

/**
 * 判断订单是否已绑定手机号码
 * @param {string} orderId
 */
export function judgeHasBindPhone(orderId: string) {
    return request({
        url: '/api/orders/judge_has_bind',
        method: 'get',
        params: {
            order_id: orderId
        }
    });
}
