<template>
    <div v-show="!comData.isInput" class="c-tab-container">
        <ul class="c-tab-bar">
            <li
                v-for="(item, index) in tabs"
                :key="index"
                :class="{'c-tab-bar-item': true, active: activeTab === item.name}"
                @click="tabChange(item)"
            >
                <img :src="activeTab === item.name ? item.activeIcon : item.icon" alt="" />
            </li>
        </ul>
    </div>
</template>

<script>
import {ref} from 'vue';
import {order} from '@/api';
import {CODE, PRODUCT_ID, CACHES} from '@/config';
import {clickLogs} from '@/utils/tools';
import {setStorage} from '@/utils/tools';

export default {
    inject: ['comData'],
    setup() {
        const activeTab = ref('tool');
        const tabs = [
            {
                title: '工具大全',
                name: 'tool',
                path: '/tool',
                icon: '/images/tab_tool.png',
                activeIcon: '/images/tab_tool_a.png'
            },
            {
                title: '个人中心',
                name: 'user',
                path: '/user',
                icon: '/images/tab_user.png',
                activeIcon: '/images/tab_user_a.png'
            }
        ];
        return {
            activeTab,
            tabs
        };
    },

    watch: {
        $route: function () {
            this.activeTab = this.$route.name.split('/')[0];
        }
    },
    mounted() {
        this.activeTab = this.$route.name.split('/')[0];
    },
    methods: {
        tabChange(item) {
            let {path, name, title} = item;
            // 防止重复点击
            if (this.activeTab === name) {
                return;
            }
            const moduleName = `底部tab切换栏-点击${title}`;
            clickLogs(moduleName, () => {
                this.activeTab = name;
                const sessionPath = sessionStorage.getItem(CACHES.TOOL_NAV);
                if (name === 'user' && !this.comData.isVip) {
                    this.goOrder();
                    return;
                }
                if (name === 'tool' && sessionPath) {
                    path = sessionPath;
                }
                this.$router.push(path);
            });
        },
        // 下单
        goOrder() {
            const channel = this.comData.channel;
            const schannel = this.comData.schannel;
            const cacheUserInfo = JSON.parse(sessionStorage.getItem(CACHES.BA_ZI_USERINFO));
            let req = {
                product_id: PRODUCT_ID,
                channel: channel
            };
            if (schannel) {
                req.schannel = schannel;
            }
            let extraInfo = {
                page_path: this.$route.path
            };
            if (cacheUserInfo) {
                const {
                    username,
                    gender,
                    dateObj: {clearHour, isSolar, submitDate}
                } = cacheUserInfo;
                const userInfo = {
                    name: username,
                    gender,
                    clear_hour: clearHour,
                    is_solar: isSolar,
                    birthday: submitDate
                };
                Object.assign(extraInfo, userInfo);
            }
            req.extend_info = JSON.stringify(extraInfo);
            order(req).then(({data, code}) => {
                if (code === CODE.SUCCESS) {
                    let {next, order_id} = data;
                    setStorage(CACHES.ORDER, order_id);
                    this.$router.push(next);
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.c-tab-container {
    height: 124px;
}

.c-tab-bar {
    height: 124px;
    background-color: #ffffff;
    box-shadow: -2px -5px 20px 0 rgba(234, 195, 139, 0.5);
    border-radius: 50px 50px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 750px;
    margin: 0 auto;

    &-item {
        width: 83px;
        height: 83px;
        border-radius: 50%;

        &.active {
            background-image: linear-gradient(0deg, #ff6c2b 0%, #ff7e3e 100%);
        }
    }
}
</style>
