import qs from 'qs';
import {collectLogs} from '@admin/mt-utils';

// 是否为iso设备
export function isIOS() {
    let ua = navigator.userAgent;
    return /iphone|ipad|ipod/i.test(ua);
}

// 是否为安卓设备
export function isAndriod() {
    let ua = navigator.userAgent;
    return /android/i.test(ua);
}

// 是否为qq浏览器
export function isQQbrowser() {
    var ua = window.navigator.userAgent.toLowerCase();
    return /mqqbrowser/i.test(ua) || /qq/i.test(ua);
}

// 是否为微信
export function isWeChat() {
    var ua = window.navigator.userAgent.toLowerCase();
    return /micromessenger/i.test(ua);
}

// 是否为pc
export function isPc() {
    let flag;
    if (
        /Android|webOS|iPhone|iPad|iPod|ucweb|BlackBerry|IEMobile|weibo|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        flag = false;
    } else {
        flag = true;
    }
    return flag;
}

// 是否是头条小程序
export function isTtMiniprogram() {
    let ua = window.navigator.userAgent.toLowerCase();
    return /toutiaomicroapp/i.test(ua);
}

//订单信息storage
export function saveOrderToStorage(key: string, value: any) {
    if (supportLocalStorage()) {
        let storageData = localStorage.getItem(key),
            newData = value,
            reg = new RegExp(value, 'ig');
        if (storageData && reg.test(storageData)) {
            return;
        }
        if (storageData && storageData !== 'null' && storageData !== 'undefined') {
            newData = `${storageData},${value}`;
        }
        localStorage.setItem(key, newData);
    }
}

//判断是否支持localStorage
export function supportLocalStorage() {
    if (!localStorage) {
        return false;
    }
    let supportFlag = false;
    try {
        localStorage.setItem('support', '1');
        localStorage.removeItem('support');
        supportFlag = true;
    } catch (error) {
        supportFlag = false;
    } finally {
        return supportFlag;
    }
}

//设置页面title
export function setPageTitle(title: string) {
    const ua = navigator.userAgent.toLowerCase();
    document.title = title;
    if (/iphone|ipad|ipod/.test(ua)) {
        var iframe = document.createElement('iframe');
        iframe.setAttribute('src', '/favicon.ico');
        iframe.style.display = 'none';
        iframe.style.width = '1px';
        iframe.style.height = '1px';
        var iframeCallback = function () {
            setTimeout(function () {
                iframe.removeEventListener('load', iframeCallback);
                document.body.removeChild(iframe);
            }, 0);
        };
        iframe.addEventListener('load', iframeCallback);
        document.body.appendChild(iframe);
    }
}

export function blurAutoMove() {
    if (isIOS()) {
        window.scrollTo(0, (document.documentElement.scrollTop || document.body.scrollTop) + 1);
    }
}

// 根据参数名获取链接参数值
export function getQueryFromUrl(key: string) {
    let queryString = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    return queryString[key];
}

// 数字小于10添加前缀0
export function addZero(num: number): string {
    return `${num >= 10 ? num : '0' + num}`;
}

// 日期选择器数据格式化
export function dateFormatter(date: string) {
    const curDate = new Date(date);
    const year = curDate.getFullYear();
    const month = curDate.getMonth() + 1;
    const day = curDate.getDate();
    const hour = curDate.getHours();
    const viewDate = `${year}年${month}月${day}日 ${hour}时`; // 用于展示的数据
    const submitDate = `${year}${addZero(month)}${addZero(day)}${addZero(hour)}00`; // 用于提交的数据
    return {
        viewDate,
        submitDate
    };
}

// 设置localStorage
export function setStorage(key: string, val: string) {
    let sup = supportLocalStorage();
    if (!sup) {
        return;
    }
    localStorage.setItem(key, val);
}

// 获取localStorage的值
export function getStorage(key: string) {
    let sup = supportLocalStorage();
    if (!sup) {
        return null;
    }
    let data = localStorage.getItem(key);
    if (data === undefined) {
        return null;
    }
    return data;
}

export function getGenderText(gender: string) {
    let text = '女';
    if (gender === 'male') {
        text = '男';
    }
    return text;
}

//根据时间点获取问候语
export function getGreeting() {
    const hour = new Date().getHours();
    let greeting = '';
    if (hour >= 4 && hour < 12) {
        greeting = '早上好';
    } else if (hour >= 12 && hour <= 19) {
        greeting = '下午好';
    } else {
        greeting = '晚上好';
    }
    return greeting;
}

/**
 * 点击事件埋点
 *
 * @param {string} name 埋点名称
 * @param {() => void} [cb] 埋点执行完成回调函数
 */
export function clickLogs(name: string, cb?: () => void) {
    collectLogs({
        log: {
            $module: name
        },
        cb
    });
}
