import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    // 重定向
    {
        path: '/',
        redirect: {
            name: 'tool'
        }
    },
    // 工具类
    {
        path: '/tool',
        name: 'tool',
        redirect: '/tool/bazi',
        component: () => import('../views/tool/index.vue'),
        meta: {
            tabBarShow: true //是否显示底部导航栏
        },
        children: [
            // 工具类-八字精算
            {
                path: '/tool/bazi',
                name: 'tool/bazi',
                component: () => import('../views/tool/bazi/index.vue')
            },
            // 工具类-运势
            {
                path: '/tool/yunshi',
                name: 'tool/yunshi',
                component: () => import('../views/tool/yunshi/index.vue')
            },
            // 工具类-排盘工具
            {
                path: '/tool/paipan',
                name: 'tool/paipan',
                component: () => import('../views/tool/paipan/index.vue')
            },
            // 工具类-求签占卜
            {
                path: '/tool/qiuqian',
                name: 'tool/qiuqian',
                component: () => import('../views/tool/qiuqian/index.vue')
            },
            // 工具类-罗盘风水
            {
                path: '/tool/luopan',
                name: 'tool/luopan',
                component: () => import('../views/tool/luopan/index.vue')
            },
            // 工具类-星盘解读
            {
                path: '/tool/xingpan',
                name: 'tool/xingpan',
                component: () => import('../views/tool/xingpan/index.vue')
            },
            // 工具类-黄历吉日
            {
                path: '/tool/huangli',
                name: 'tool/huangli',
                component: () => import('../views/tool/huangli/index.vue')
            },
            // 工具类-解梦
            {
                path: '/tool/jiemeng',
                name: 'tool/jiemeng',
                component: () => import('../views/tool/jiemeng/index.vue')
            }
        ]
    },
    // 用户中心
    {
        path: '/user',
        name: 'user',
        meta: {
            tabBarShow: true //是否显示底部导航栏
        },
        component: () => import('../views/user/index.vue')
    },
    // 挽留页-八字精算-八字财运
    {
        path: '/detain/bazi/bazicaiyun',
        name: 'detain/bazi/bazicaiyun',
        component: () => import('../views/detain/bazi/bazicaiyun/index.vue')
    },
    // 挽留页-八字精算-八字健康
    {
        path: '/detain/bazi/bazijiankang',
        name: 'detain/bazi/bazijiankang',
        component: () => import('../views/detain/bazi/bazijiankang/index.vue')
    },
    // 挽留页-八字精算-八字命格
    {
        path: '/detain/bazi/bazimingge',
        name: 'detain/bazi/bazimingge',
        component: () => import('../views/detain/bazi/bazimingge/index.vue')
    },
    // 挽留页-八字精算-八字事业
    {
        path: '/detain/bazi/bazishiye',
        name: 'detain/bazi/bazishiye',
        component: () => import('../views/detain/bazi/bazishiye/index.vue')
    },
    // 挽留页-八字精算-八字姻缘
    {
        path: '/detain/bazi/baziyinyuan',
        name: 'detain/bazi/baziyinyuan',
        component: () => import('../views/detain/bazi/baziyinyuan/index.vue')
    },
    // 挽留页-八字精算-父母子女
    {
        path: '/detain/bazi/fumuzinv',
        name: 'detain/bazi/fumuzinv',
        component: () => import('../views/detain/bazi/fumuzinv/index.vue')
    },
    // 挽留页-八字精算-流年运势
    {
        path: '/detain/bazi/liunianyunshi',
        name: 'detain/bazi/liunianyunshi',
        component: () => import('../views/detain/bazi/liunianyunshi/index.vue')
    },
    // 挽留页-八字精算-十年大运
    {
        path: '/detain/bazi/shiniandayun',
        name: 'detain/bazi/shiniandayun',
        component: () => import('../views/detain/bazi/shiniandayun/index.vue')
    },
    // 挽留页-八字排盘
    {
        path: '/detain/paipan',
        name: 'detain/paipan',
        component: () => import('../views/detain/paipan/index.vue')
    },
    // 登录
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/index.vue')
    },
    // 更换绑定手机号码
    {
        path: '/phonechange',
        name: 'phonechange',
        component: () => import('../views/login/phoneChange.vue')
    },
    // 填写用户信息
    {
        path: '/collectuserinfo',
        name: 'collectuserinfo',
        component: () => import('../views/collect/collectuserinfo/index.vue')
    },
    // 展示用户档案
    {
        path: '/showuserfile',
        name: 'showuserfile',
        component: () => import('../views/collect/showuserfile/index.vue')
    },
    // 结果页-八字排盘
    {
        path: '/result/paipan',
        name: 'result/paipan',
        component: () => import('../views/result/paipan/index.vue')
    },
    // 结果页-八字精算-八字财运
    {
        path: '/result/bazi/bazicaiyun',
        name: 'result/bazi/bazicaiyun',
        component: () => import('../views/result/bazi/bazicaiyun/index.vue')
    },
    // 结果页-八字精算-八字健康
    {
        path: '/result/bazi/bazijiankang',
        name: 'result/bazi/bazijiankang',
        component: () => import('../views/result/bazi/bazijiankang/index.vue')
    },
    // 结果页-八字精算-八字命格
    {
        path: '/result/bazi/bazimingge',
        name: 'result/bazi/bazimingge',
        component: () => import('../views/result/bazi/bazimingge/index.vue')
    },
    // 结果页-八字精算-八字事业
    {
        path: '/result/bazi/bazishiye',
        name: 'result/bazi/bazishiye',
        component: () => import('../views/result/bazi/bazishiye/index.vue')
    },
    // 结果页-八字精算-八字姻缘
    {
        path: '/result/bazi/baziyinyuan',
        name: 'result/bazi/baziyinyuan',
        component: () => import('../views/result/bazi/baziyinyuan/index.vue')
    },
    // 结果页-八字精算-父母子女
    {
        path: '/result/bazi/fumuzinv',
        name: 'result/bazi/fumuzinv',
        component: () => import('../views/result/bazi/fumuzinv/index.vue')
    },
    // 结果页-八字精算-流年运势
    {
        path: '/result/bazi/liunianyunshi',
        name: 'result/bazi/liunianyunshi',
        component: () => import('../views/result/bazi/liunianyunshi/index.vue')
    },
    // 结果页-八字精算-十年大运
    {
        path: '/result/bazi/shiniandayun',
        name: 'result/bazi/shiniandayun',
        component: () => import('../views/result/bazi/shiniandayun/index.vue')
    },
    // 结果页-运势详情
    {
        path: '/result/yunshi',
        name: 'result/yunshi',
        component: () => import('../views/result/yunshi/index.vue')
    },
    // 结果页-运势关注
    {
        path: '/result/follow',
        name: 'result/follow',
        component: () => import('../views/result/follow/index.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
