const PRODUCT_ID = 'csdq_vip_tool'; // 主要付费点
const PRODUCT_ID_FORTUNE = 'vip_times_tool'; // 解锁限定名额付费点(超过3次需要付费解锁，用于运势)
const APP_ID = '606'; // 大数据埋点ID
const ZUI_XIAO_LI_DU_ID = '162685020400000000'; //最小粒度id

const CHANNEL = 'h5ff_6mni_00002'; // 跳转到在线携带的channel（用于屏蔽资源位）
const SCHANNEL = `${APP_ID}_yfsc_tldw_00010`; // schannel

// 缓存命名
const CACHES = {
    TOKEN: 'csdq_token', // token
    UID: 'csdq_uid', // 用户id
    WX_USERINFO: 'csdq_wx_userinfo', // 微信用户信息
    WX_OPENID: 'csdq_wx_openid', // 微信openid
    HISTORY_ORDER: 'csdq_history_order', // 历史订单
    BA_ZI_USERINFO: 'csdq_bazi_userinfo', // 工具-个人分析-用户信息
    YUN_SHI_USERINFO: 'csdq_yunshi_userinfo', // 工具-运势-用户信息
    TOOL_NAV: 'csdq_tool_nav', // 工具-顶部导航-选中项
    PAI_PAN_ID: 'csdq_pai_pan_id', // 工具-排盘-选中项
    QIU_QIAN_ID: 'csdq_qiu_qian_id', // 工具-求签-选中项
    QI_MING_ID: 'csdq_qi_ming_id', // 工具-起名-选中项
    JIE_MENG_ID: 'csdq_jie_meng_id', // 工具-解梦-选中项
    LUO_PAN_ID: 'csdq_luo_pan_id', // 工具-罗盘-选中项
    XING_PAN_ID: 'csdq_xing_pan_id', // 工具-星盘-选中项
    USER_TAB: 'csdq_user_tab', // 用户中心-顶部导航
    ORDER_QUERY_TYPE: 'csdq_order_type', // 用户中心-订单查询
    ORDER: 'csdq_order_id', // 缓存最近的orderId
    FOLLOW_ID: 'csdq_follow_id', // 关注的人ID
    POP_WECHAT_SHOW: 'csdq_pop_wechat_show', // 公号导粉弹窗显示标识
    TOOL_LIST_DATA: 'csdq_tool_list_data' // 工具列表数据
};

// 八字精算八大模块product_id
const BZJS_PRODUCT = {
    BA_ZI_MING_GE: 'ba_zi_ming_ge', // 八字命格
    BA_ZI_YIN_YUAN: 'xian_tian_yin_yuan', // 八字姻缘
    BA_ZI_CAI_YUN: 'xian_tian_cai_yun', // 八字财运
    BA_ZI_SHI_YE: 'xian_tian_shi_ye', // 八字事业
    BA_ZI_JIAN_KANG: 'xian_tian_jian_kang', // 八字健康
    FU_MU_ZI_NV: 'fu_mu_zi_nv', // 父母子女
    LIU_NIAN_YUN_SHI: 'liu_nian_yun_cheng', // 流年运势
    SHI_NIAN_DA_YUN: 'shi_nian_da_yun' // 十年大运
};

// 工具ID
const TOOL_ID = {
    BA_ZI: 'personal.analysis',
    HUANG_LI: 'huang.li',
    JIE_MENG: 'analysis.dream',
    LUO_PAN: 'feng.shui',
    PAI_PAN: 'tray.tool',
    QIU_QIAN: 'pray.divine',
    XING_PAN: 'interpretation.chart',
    YUN_SHI: 'daily.luck'
};

// 工具product_id
const TOOL_PRODUCT_ID = {
    YUN_SHI: 'mei_ri_yun_shi',
    BZ_ZI_PAI_PAN: 'ba_zi_pai_pan' // 八字排盘
};

// CODE 定义
const CODE = {
    SUCCESS: 10000, // 成功
    LOGIN_EXPIRED: 40003, // 登录过期
    NO_PAY: 40005 // 未付款
};

// 页面
const PAGE = {
    SHOW_USER_FILE: '/showuserfile', // 查看用户档案
    COLLECT_USER_INFO: '/collectuserinfo', // 收集用户信息
    RESULT_YUNSHI: '/result/yunshi', // 结果-运势
    LOGIN: '/login', // 登录页面
    TOOL_BAZI: '/tool/bazi', // 工具-个人分析
    KEFU: 'https://linghit.qiyukf.com/client?k=da58ce0115a1232c79a01c472ae24164&wp=1qtype=13111' // 客服链接
};

export {
    PRODUCT_ID,
    PRODUCT_ID_FORTUNE,
    APP_ID,
    ZUI_XIAO_LI_DU_ID,
    CACHES,
    BZJS_PRODUCT,
    TOOL_ID,
    TOOL_PRODUCT_ID,
    CODE,
    PAGE,
    CHANNEL,
    SCHANNEL
};
