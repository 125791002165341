/**
 * 大数据埋点
 *
 * 文档：https://doc.linghitai.com/web/#/p/b3cbb07949f2247258f3baf077e29f82
 * */
import Cookies from 'js-cookie';
import {getQueryFromUrl} from '../tools';
import {CACHES, APP_ID, ZUI_XIAO_LI_DU_ID} from '@/config';

// 环境
const isLocalhost = !!window.location.host.match(
    /(localhost|sandbox|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})/i
)
    ? true
    : false;

// 初始化数据
const defaultConfig = {
    app_id: APP_ID, // 业务线id
    product_id: ZUI_XIAO_LI_DU_ID, //最小粒度id
    single_page: true, //是否单页应用
    show_log: isLocalhost ? true : false, //发送数据时，是否在控制台显示日志信息
    is_repo_avg_duration: isLocalhost ? false : true, //是否开启自动页面浏览时长上报（量大不要开启）
    repo_avg_time: 1000,
    user_center_id: getQueryFromUrl('u_id') || Cookies.get(CACHES.UID) || ''
};

const bigDataInit = () => {
    let timer: any = null;
    let trackInit = () => {
        clearTimeout(timer);
        if (window.sense) {
            window.sense.init(defaultConfig);
        } else {
            timer = setTimeout(trackInit, 1000);
        }
    };
    trackInit();
};

export default bigDataInit;
