// 微信授权相关逻辑处理
import qs from 'qs';
import Cookies from 'js-cookie';
import {onMounted, provide, reactive} from 'vue';
import {isWeChat, setStorage, getStorage} from '@/utils/tools';
import {fetchWxCode, fetchWxUser, userLogin, judgeOpenid} from '@/api';
import {getQueryFromUrl} from '@/utils/tools';
import {CACHES, CODE} from '@/config';

const isInWechat = isWeChat(); // 是否为微信环境

interface IUserInfo {
    openid: string;
    username: string;
    gender: string;
    headimgurl: string;
    unionid: string;
}

interface IObj {
    [key: string]: any;
}

const useWechatAuth = () => {
    const wxUserInfo = reactive({
        openid: '',
        username: '',
        gender: '',
        headimgurl: '',
        unionid: ''
    }); // 微信用户信息
    provide('wxUserInfo', wxUserInfo);
    // 添加sdk
    const addSDK = () => {
        let mta = document.createElement('script'),
            s = document.getElementsByTagName('script')[0];
        mta.src = 'http://res.wx.qq.com/open/js/jweixin-1.6.0.js';
        s.parentNode && s.parentNode.insertBefore(mta, s);
    };
    // 初始化
    const init = () => {
        if (!isInWechat) {
            return;
        }
        addSDK();
        wechatInit();
    };

    // 设置微信用户信息
    const setWxUserInfo = (userInfo: IUserInfo) => {
        const {openid, username, gender, headimgurl, unionid} = userInfo;
        wxUserInfo.openid = openid;
        wxUserInfo.username = username;
        wxUserInfo.gender = gender;
        wxUserInfo.headimgurl = headimgurl;
        wxUserInfo.unionid = unionid;

        onLogin(); // 默认登录
    };

    // 微信初始化
    const wechatInit = () => {
        let cacheUserInfo = getStorage(CACHES.WX_USERINFO), // 获取缓存微信用户信息
            userInfo = cacheUserInfo ? JSON.parse(cacheUserInfo) : {}, // 转换用户信息
            cacheMiss = false, // 数据缓存时间是否过期，cacheMiss=true 表示缓存失效
            {data_last_time} = userInfo;
        // console.log('cacheUserInfo:', cacheUserInfo);
        if (data_last_time) {
            let cur_time = new Date().getTime();
            cacheMiss = cur_time - data_last_time > 3 * 24 * 60 * 60 * 1000; //缓存3天 ,timeFlg = false 标识过期
        }
        if (userInfo.openid && !cacheMiss) {
            Cookies.set(CACHES.WX_OPENID, userInfo.openid, {expires: 3});
            setWxUserInfo(userInfo);
            if (window.sense) {
                window.sense.setUserID({userId: userInfo.openid, userIdType: '$openid'});
                window.sense.link({targetId: userInfo.openid, linkType: 'cookie->openid'});
            }
            return;
        }

        const code = getQueryFromUrl('code');
        if (code) {
            //已发起授权并拿到code
            getWechatInfo(String(code));
        } else {
            startAuth();
        }
    };

    //发起授权
    const startAuth = () => {
        let url = getCbUrl();
        // snsapi_userinfo 获取用户信息
        fetchWxCode(url, 'snsapi_userinfo').then(({data}) => {
            if (data.oauth_url) {
                location.href = data.oauth_url;
            }
        });
    };

    // 获取回调地址
    const getCbUrl = () => {
        let url = location.href,
            params = qs.parse(location.search, {ignoreQueryPrefix: true});
        //获取用户信息失败，需要重新发起授权，授权链接需要去掉code
        if (params.code) {
            let index = url.indexOf('?'),
                link = url.slice(0, index);
            delete params.code;
            let queryStr = qs.stringify(params),
                newUrl = `${link}?${queryStr}`;
            return newUrl;
        }
        return url;
    };

    // 根据code获取用户信息
    const getWechatInfo = (code: string) => {
        fetchWxUser(code)
            .then(({data}) => {
                if (data.openid) {
                    if (window.sense) {
                        window.sense.setUserID({userId: data.openid, userIdType: '$openid'});
                        window.sense.link({targetId: data.openid, linkType: 'cookie->openid'});
                    }
                    let userInfo = {
                        openid: data.openid,
                        username: data.nickname,
                        gender: data.gender,
                        headimgurl: data.headimgurl,
                        unionid: data.unionid,
                        hasOpenid: true,
                        data_last_time: new Date().getTime()
                    };
                    setWxUserInfo(userInfo);
                    Cookies.set(CACHES.WX_OPENID, data.openid, {expires: 3});
                    setStorage(CACHES.WX_USERINFO, JSON.stringify(userInfo));
                }
            })
            .catch((err) => {
                //发生错误，重新授权一次
                let count = getQueryFromUrl('count');
                if (!count) {
                    let url = `${location.origin}${location.pathname}${location.search}${
                        location.search ? '&' : '?'
                    }count=1`;
                    location.href = url;
                }
            });
    };

    const onLogin = async () => {
        const token = Cookies.get(CACHES.TOKEN);
        const openid = wxUserInfo.openid;
        let canLogin = false;
        // 有token或当前为登录页则不自动登录
        if (token || location.pathname === '/login') {
            return;
        }
        // 判断是否可以自动登录
        try {
            const res: IObj = await judgeOpenid(openid);
            canLogin = res.code === CODE.SUCCESS;
        } catch (err) {
            console.log(err);
        }
        if (!canLogin) {
            console.warn('未绑定手机号码，不可自动登录');
            return;
        }
        let req = {
            openid
        };
        userLogin(req).then(({data, code}: IObj) => {
            if (code === CODE.SUCCESS) {
                let {token, token_expire_at} = data;
                if (token) {
                    const expires = new Date(token_expire_at.replace(/\-/g, '/'));
                    Cookies.set(CACHES.TOKEN, token, {expires}); // 缓存token
                    Cookies.set(CACHES.UID, token, {expires}); // 缓存用户id,用于埋点
                    // console.log('useWechatAuth token:', token);
                    location.reload(); // 刷新一遍 更新数据
                }
            }
        });
    };

    onMounted(() => {
        init();
    });
};

export default useWechatAuth;
